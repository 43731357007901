$hero-color: #EA3323;

@mixin rametto {
  font-family: "Rammetto One", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  .mixed-case {
    text-transform: none;
  }
}

@mixin text-glow {
  filter: drop-shadow(0px 0px 24px $hero-color) drop-shadow(0px 0px 12px $hero-color);
}

@mixin josefin {
  font-family: "Josefin Sans", Arial, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin jura {
  font-family: "Jura", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin arial {
  font-family: Arial, sans-serif;
}

@mixin border {
  border-radius: 0 0 13px 0;
}

@mixin cta {
  @include rametto;
  @include border;
  margin-top: 25px;
  display: inline-block;
  padding: 10px 15px;
}

@mixin hero-text {
  @include rametto;
  margin: 32px 0;
  font-size: 32px;
  @media (max-width: 950px) {
    font-size: 24px;
  }
  @media (max-width: 700px) {
    font-size: 14px;
  }
}

@mixin subtitle-text {
  @include rametto;
  margin: 32px 0;
  font-size: 32px;
  @media (max-width: 950px) {
    font-size: 36px;
  }
  @media (max-width: 700px) {
    font-size: 40px;
  }
}

@mixin secondary-header {
  .page-header {
    padding-top: 50px;
    padding-bottom: 50px;
    background: url('../images/static-bg.png') no-repeat;

    .header-text {
      @include subtitle-text;
      @include text-glow;
    }
  }
}
